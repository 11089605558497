.sso-popup * {
    color: #888;
}

.kfa-font {
    font-family: 'KFAGothic', sans-serif;
}

.top{
    padding-left:40px;
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.top h1{
    vertical-align:top;
    font-size: 40px;
    margin-block-start:0;
    display: inline-block;
    padding-right: 12px;
}

.top h1 .cursor{
    vertical-align:top;
    font-size: 40px;
    margin-block-start:0;
    padding-right: 6px;
    animation: type .5s alternate infinite;
}

.mid h3 {
    margin: 0 auto 1rem;
}
.mid ul {
    display: inline-block;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    padding-inline-start: 40px;
}
.mid li {
    list-style: none;
    text-align: left;
}
.bottom ul{
    display: inline-block;
}

.bottom li {
    list-style: square;
    text-align: left;
    font-size: 0.875rem;
}

@keyframes type{
    from{box-shadow: inset -3px 0px 0px #888;}
    to{box-shadow: inset -3px 0px 0px transparent;}
}

@media (min-width:700px) , (min-height:500px){
    .top h1{
        vertical-align:top;
        font-size: 40px;
        display: inline-block;
    }
}
.w-200{width:200px !important; }
.m-l-40{margin-left: 40px; }
.div-display-inline-b{display:inline-block;}

.btn-skew-normal-red { display: flex; align-items: center; justify-content: center; position: relative; max-width: 288px;
    color: #ffffff; font-weight: 500; height: 3.5rem; max-height:56px; font-size: 1rem; padding: 0; flex: 0 1 18rem; border-radius: 0; z-index: 1;
    background-color:#ffffff; border:0;
}
.btn-skew-normal-red::before { z-index: -1; position: absolute; top: 0; display: block; box-sizing: inherit;
    width: calc(100% - (3.5rem * 0.275637)); height: 100%; content: "";
    border-radius:1rem; background-color: #e6002d;
}

.bg-crop {
    display:inline-block;
    width:140px;
    height:190px;
    overflow:hidden;
    position:relative;
}
.bg-tiger {
    position:absolute;
    top:10px;
}
