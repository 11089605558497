* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; 
    -webkit-tap-highlight-color: transparent;
}
html, body, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, 
abbr, address, big, cite, code, del, dfn, em, font, img, ins, q, s, samp, small, 
strike, strong, sub, sup, tt, var, b, u, i, ul, ol, li, dl, dt, dd, table, caption,
tbody, tfoot, thead, tr, th, td, fieldset, fo7m, label, legend, input, button, textarea, select 
{margin:0; padding: 0; }
html, body { margin: 0; padding: 0; height: 100%;}

body {  
    margin: 0; 
}
input, select, textarea { 
    border: 1px solid #ddd;
    color: #fff;
}
textarea { 
    resize: none; 
    padding: 24px;
    min-height: 180px;
    vertical-align: top;
}
input:focus, select:focus, textarea:focus,
input:focus-visible, select:focus-visible, textarea:focus-visible { 
   outline: none;
}
input::placeholder,
textarea::placeholder {
    
}
/*input[type="password" i] { font-family:"Droid Sans"; }*/
select {
    
}
select::-ms-expand {
    display: none;
}
a { color: currentColor; text-decoration: none; }
a:hover {
    text-decoration: underline;
    text-underline-position: under;
}
img {
    vertical-align: top;
}
ul { list-style: none; }
hr { margin: 0; border: none; border-top: 1px solid #ddd; width: 100%; }