:root {
	--background-main: #161617;;
	--background-nomal: #28292E;
	--background-active: #1D1D20;
	--background-inactive: #28292E;
	--background-submit: #E6002D;
	--background-submit-active: #AB0006;

	--color-noaml: #fff;
	--color-inactive : #6D6D6D;

	--size-xl : 64px;
	--size-lg : 56px;
	--size-md : 48px;
	--size-sm : 40px;
	--size-tiny : 32px;

	--border-radius: 4px;
}

.btn {
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: var(--border-radius);
	background-color: var(--background-submit);
	min-width: 90px;
	padding: 0 24px;
	border: none;
	height: var(--size-md);
	color: var(--color-noaml);
	cursor: pointer;
}

button.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--background-submit);
	width: 100%;
	height: 64px;
	font-size: 18px;
	font-weight: 700;
}
button.btn:disabled {
	background-color: var(--color-gray);
	color: var(--color-nt-600);
	cursor: default;
}

.btn:hover,
.btn.active {
	background-color: var(--background-submit-active);
	text-decoration: none;
}

.btn-default,
button.btn-default {
	background-color: var(--background-nomal);
}
.btn-default:hover,
.btn-default.active {
	background-color: var(--background-active) !important;
}
.btn-default.inactive {
	pointer-events: none;
	background-color: var(--background-inactive) !important;
	color: var(--color-inactive) !important;
	cursor: default;
}
.btn.confirm {
	width: 120px !important;
	color: var(--color-green);
	background-color: var(--background-main) !important;
	cursor: default;
	pointer-events: none;
}
.btn.confirm .icon {

}

.btn-revers {
	background-color: var(--background-nomal) !important;
	color: #fff !important;
}
.btn-revers.active {
	background-color: #fff !important;
	color: var(--background-main) !important;
}

.btn-cancle {
	border: 1px solid var(--color-gray);
	background-color: var(--color-main);
}
.btn-cancle:hover {
	background-color: var(--background-nomal) !important;
}

button.btn {
	width: 100%;

}

.btn .icon {

}
.btn span + .icon {
	margin-left: 8px;
}
.btn .icon + span {
	margin-left: 8px;
}

.btn-xl {
	height: var(--size-xl);
}
.btn-lg {
	height: var(--size-lg);
}
.btn-md {
	height: var(--size-md);
}
.btn-sm {
	height: var(--size-sm);
	padding: 0 16px;
}
.btn-tiny {
	height: var(--size-tiny);
	padding: 0 12px;
	font-size: 14px;
	min-width: inherit;
}
.btn-more {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 200px;
	height: 72px;
	border-radius: 40px;
	border: 1px solid currentColor;
	padding: 0 48px;
}
.btn-more:after {
	content: "";
	width: 24px;
	height: 24px;
	background-image: url(../img/asset/icon/ic_more.svg);
	background-repeat: no-repeat;
	background-position: right center;
}
.btn-more:hover {
	text-decoration: none;
	/*opacity: 0.8;*/
}
.btn-more:hover:after {
	background-image: url(../img/asset/icon/ic_more_active.svg);
}


@media(max-width: 1024px) {
	.btn  {
		font-size: 14px;
		flex-shrink: 0;
	}
	.btn span + .icon {
		margin-right: 4px;
	}
	.btn .icon + span {
		margin-left: 4px;
	}

	.btn-xl {
		height: var(--size-xl);
	}
	.btn-lg {
		height: var(--size-lg);
	}
	.btn-md {
		height: var(--size-md);
	}
	.btn-sm {
		height: 32px;
		padding: 0 13px;
	}
	.btn-tiny {
		height: var(--size-tiny);
		padding: 0 12px;
		font-size: 14px;
		min-width: inherit;
	}

	.btn-more {
		font-size: 13px;
		width: 112px;
		height: 40px;
		padding: 0 20px;
	}
	.btn-more:after {
		width: 14px;
		background-size: 14px;
	}

	.mbile_buger_btn {
		flex-shrink: 0;
		width: 24px;
		height: 24px;
		margin-right: 12px;
		background-image: url(../img/asset/icon/ic_buger.svg);
		background-repeat: no-repeat;
		background-position: center;
	}
	.mbile_buger_btn.active {
		background-image: url(../img/asset/icon/ic_close.svg);
	}

}